<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="批次">
              <a-input v-model="queryParam.batch" placeholder="请准确输入批次"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-row :gutter="48">
      <a-col :md="2" :sm="24">
        <div class="table-operator">
          <a-button type="primary" icon="download" @click="downloadExcel">下载模板</a-button>
        </div>
      </a-col>
      <a-col :md="2" :sm="24">
        <div class="table-operator">
          <a-button type="primary" icon="download" @click="importCeb303">导入</a-button>
        </div>
      </a-col>
    </a-row>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <span v-if="record.status === 1">
            <a @click="handleUpdate(record, 2)">推送</a>
            <a-divider type="vertical"/>
            <a @click="handleUpdate(record, 3)">取消</a>
            <a-divider type="vertical"/>
            <a @click="handleRead(record)">详情</a>
          </span>
          <span v-if="record.status !== 1">
            <a @click="handleRead(record)">详情</a>
          </span>
        </template>
      </span>
    </s-table>
    <a-modal v-model="batchVisible" title="导入" width="350px" @ok="handleBatchOk" @cancel="handleBatchCancel">
      <a-input addon-before="批次" v-model="req.batch" style="width: 100%; margin-bottom: 20px" placeholder="必填"/>
      <a-upload
        name="file"
        :multiple="false"
        accept=".xlsx"
        :show-upload-list="false"
        @change="handleChange"
        :before-upload="handleExcel">
        <a-button type="primary">
          <a-icon type="upload" />
          上传文件
        </a-button>
      </a-upload>
    </a-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  e_ceb303_import_excel_create,
  e_ceb303_import_excel_list,
  e_ceb303_import_list,
  e_ceb303_import_partial_update
} from '@/api/ceb303'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      req: {},
      matchId: 0,
      itemRecord: {},
      batchVisible: false,
      previewurl: '',
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      selectvisible: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'batch',
          ellipsis: true
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.export_order_status[text] || text
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '250px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return e_ceb303_import_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    console.log(this.$Dictionaries.corporation_id, 123121)
  },
  methods: {
    handleUpdate (record, target) {
      e_ceb303_import_partial_update({ target: target }, record.id).then((res) => {
        this.$refs.table.refresh()
      })
        .catch((err) => {
          console.log(err)
          this.$refs.table.refresh()
        })
    },
    handleRead (record) {
      console.log(record)
      this.$router.push({ path: '/e/customs_order/export/order/read', query: { batch: record.batch } })
    },
    handleBatchOk () {
      this.req.batch = null
      this.batchVisible = false
    },
    handleBatchCancel () {
      this.req.batch = null
      this.batchVisible = false
    },
    importCeb303 () {
      this.batchVisible = true
    },
    downloadExcel () {
      e_ceb303_import_excel_list().then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `ceb303.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleChange (info) {},
    handleExcel (file) {
      return new Promise((resolve, reject) => {
        if (!this.req.batch) {
          this.$message('请先填写批次')
          return
        }
        const extension = file.name.split('.')[1] === 'xlsx'
        if (!extension) {
          reject(new Error('上传文件只能是xlsx格式'))
          this.$message.error('上传文件只能是xlsx格式')
        } else {
          // resolve()
          this.uploadFile(file).then(res => {
            console.log(res)
            if (res.code === 1000) {
              this.handleBatchOk()
              this.$refs.table.refresh()
            }
          })
        }
      })
    },
    uploadFile (file) {
      return new Promise(resolve => {
        const formData = new FormData()
        formData.append('corporation_id', this.$Dictionaries.corporation_id)
        formData.append('batch', this.req.batch)
        formData.append('excel', file)
        e_ceb303_import_excel_create(formData).then(res => {
          resolve(res)
        })
      })
    },
    async asyncUploadFile (file) {
      const returnData = await this.uploadFile(file)
      return returnData
    },
    excelImportCancel () {},
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleRecord (record) {
      console.log(record)
      this.matchId = record.id
      this.itemRecord = record
      // this.$emit('onRead', record)
      this.visible = !this.visible
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
